<template>
  <div class="HospitalManagement">
    <div class="searcherC">
      <div style="display: flex; align-items: center">
        <a-button>渠道:</a-button>

        <div class="provinces">
          <div v-for="(item, index) in channelList" :key="index">
            <a-button
              style="margin: 5px; font-weight: bold; font-family: 微软雅黑"
              size="small"
              :class="{ liBackground: provincesStyle == index }"
              id="provincesBtn"
              @click="channelSearch(item.value, index)"
              >{{ item.name }}</a-button
            >
          </div>
        </div>
      </div>
      <a-divider :dashed="true" />
      <div style="display: flex; align-items: center">
        <a-button>位置:</a-button> &nbsp;

        <div class="City">
          <div v-for="(item, index) in locationList" :key="index">
            <a-button
              style="margin: 5px; font-weight: bold; font-family: 微软雅黑"
              size="small"
              :class="{ liBackground: cityStyle == index }"
              id="provincesBtn"
              @click="locationSearch(item.value, index)"
              >{{ item.name }}</a-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="hospital_list">
      <div class="top_cont">
        <div class="DrugCont-top-input">
          <span>操作日期：</span>
          <a-range-picker style="margin-right: 15px" @change="onChange" />
          <a-button type="primary" @click="search"> 查询 </a-button>
        </div>
        <div class="DrugCont-top-input">
          <a-button @click="deriveBtn">
            <a-icon style="color: #00a854" type="file-excel" />
            批量导出
          </a-button>
        </div>
      </div>
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        :columns="columns"
        :data-source="data"
      >
        <span slot="channel" slot-scope="text, record">
          <span>{{
            text == "app" ? "APP" : text == "mina" ? "小程序" : "官网"
          }}</span>
        </span>
        <span slot="location" slot-scope="text, record">
          <span>{{returnTitle(text)}} </span>
          <!-- <span>{{
            text == "SEARCH"
              ? "全局搜索"
              : text == "EDUCATION_LIST"
              ? "进修招聘主页"
              : text == "DOCUMENT_LIST"
              ? "文献指南主页"
              : text == "VOD_LIST"
              ? "点播按内容查看列表"
              : text == "DRUG_LIST"
              ? "药品查询"
              : text == "VOD_EXPERT_LIST"
              ? "点播按专家查看列表"
              : text == "MY_VOD_COLLECTION"
              ? "我收藏的点播列表"
              : text == "MY_ARTICLE_COLLECTION"
              ? "我收藏的文章列表"
              : ""
          }}</span> -->
        </span>
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ pageNo }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="pageNo"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getSearch, getExcel } from "@/service/MedicalConference_y";
const columns = [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center",
    width: "6%",
  },
  {
    title: "医院",
    dataIndex: "hospitalName",
    key: "hospitalName",
    align: "center",
    ellipsis: true,
    width: "15%",
  },
  {
    title: "科室",
    dataIndex: "hospitalOffices",
    key: "hospitalOffices",
    align: "center",
    width: "8%",
  },
  {
    title: "职称",
    dataIndex: "jobTitle",
    key: "jobTitle",
    align: "center",
    ellipsis: true,
    width: "8%",
  },
  {
    title: "搜索关键字/条件",
    dataIndex: "keyword",
    key: "keyword",
    align: "center",
    ellipsis: true,
    width: "15%",
  },
  {
    title: "搜索渠道",
    dataIndex: "channel",
    key: "channel",
    align: "center",
    ellipsis: true,
    width: "13%",
    scopedSlots: { customRender: "channel" },
  },
  {
    title: "搜索位置",
    dataIndex: "location",
    key: "location",
    align: "center",
    width: "13%",
    scopedSlots: { customRender: "location" },
  },
  {
    title: "操作时间",
    dataIndex: "createdTime",
    key: "createdTime",
    align: "center",
    width: "13%",
  },
];

export default {
  data() {
    return {
      channel: "",
      location: "",
      startTime: "",
      endTime: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      columns,
      data: [],
      provincesStyle: 0,
      cityStyle: 0,
      channelList: [
        { name: "全部", value: "" },
        { name: "APP", value: "app" },
        { name: "小程序", value: "mina" },
        { name: "官网", value: "web" },
      ],
      locationList: [
        { name: "全部", value: "" },
        { name: "全局搜索", value: "SEARCH" },
        { name: "进修招聘主页", value: "EDUCATION_LIST" },
        { name: "文献指南主页", value: "DOCUMENT_LIST" },
        { name: "点播按内容查看列表", value: "VOD_LIST" },
        { name: "点播按专家查看列表", value: "VOD_EXPERT_LIST" },
        { name: "药品查询", value: "DRUG_LIST" },
        { name: "我收藏的点播列表", value: "MY_VOD_COLLECTION" },
        { name: "我收藏的文章列表", value: "MY_ARTICLE_COLLECTION" },
        {name:"全局搜索-点播列表",value:"SEARCH_VOD"},
        {name:"全局搜索-文献",value:"SEARCH_DOCUMENT"},
        {name:"全局搜索-进修招聘",value:"SEARCH_EDUCATION"},
        {name:"全局搜索-文章",value:"SEARCH_ARTICLE"},
        {name:"全局搜索-药品",value:"SEARCH_DRUG"},
        {name:"全局搜索-职称考试",value:"SEARCH_EXAM"}
      ],
    };
  },
  created() {
    this.load();
    
  },
  methods: {
    async load() {
      let data = {
        channel: this.channel,
        location: this.location,
        startTime: this.startTime,
        endTime: this.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      const res = await getSearch(data);
      if (res.code == 0) {
        this.data = res.data;
        this.total = res.count;
      }
      this.returnTitle()
    },

    channelSearch(value, index) {
      this.channel = value;
      (this.provincesStyle = index),
        (this.pageNo = 1),
        (this.pageSize = 10),
        this.load();
    },

    locationSearch(value, index) {
      this.cityStyle = index;
      this.location = value;
      (this.pageNo = 1), this.load();
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    // 分页查询
    pageChange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.load();
    },
    search() {
      this.pageNo = 1;
      this.load();
    },
    async deriveBtn() {
      const data = {
        channel: this.channel,
        location: this.location,
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: "搜索记录",
      };
      const res = await getExcel(data);
    },
    returnTitle(text){
     for (let i=0;i<this.locationList.length;i++){
        if(text === this.locationList[i].value){
          return this.locationList[i].name
        }
    }
    }
}
}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}
.HospitalManagement {
  margin: -15px -15px;
  background-color: whitesmoke;
  .searcherC {
    padding: 15px;
    margin: 0 auto;
    background-color: white;
    height: auto;
    font-size: 15px;
    border-bottom: 15px solid #f0f2f5;
    .ant-btn {
      border: none !important;
      box-shadow: none !important;
    }
    .provinces {
      margin-left: 20px;
      display: flex;
      // flex-wrap: wrap;
    }
    .City {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
  }
  .Searcher {
    padding: 5px;
    height: 120px;
    width: 100%;
    margin: 10px auto;
    background-color: white;
    .searchH {
      margin: 20px;
      display: inline-flex;
      align-items: center;
    }
  }
  .hospital_list {
    background-color: white;
    margin: 0 auto;
    padding: 15px;
    .top_cont {
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageLeft {
  font-size: 15px;
  color: #929292;
}
</style>
